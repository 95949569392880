<template>
  <el-col :span="11" class="header__search">
    <el-input v-model="searchText" class="header__search--input" placeholder="Поиск" :loading="loading" @click="onInputClick" @keydown="debounceSearch">
      <template #suffix>
        <el-icon size="1.2em">
          <Search />
        </el-icon>
      </template>
    </el-input>
    <div v-if="showMenu" v-click-outside="onClickOutside" class="search-result-menu">
      <div class="search-result-menu__wrapper">
        <div class="search-result-menu__type-selector__wrapper">
          <div
            v-for="searchType in types"
            :key="searchType.value"
            class="search-result-menu__type-selector__tab"
            :class="{'search-result-menu__type-selector__tab--selected' : searchType.value=== type }"
            @click="type = searchType.value; getSerachResults()"
          >
            {{ searchType.text }}
          </div>
        </div>
        <div id="loading">
          <div v-if="type === 'all'">
            <template v-if="results.releases.length">
              <div class="mb3 mt3 search-result-menu__header" style="font-weight: 400; font-size: 1em;">
                Релизы
              </div>
              <SearchResultCard v-for="result in results.releases" :key="result.id" :type="'releases'" :result="result" @click="redirect(result.releaseId || result.id,result.requestId)" />
            </template>
            <template v-if="results.musicVenues.length">
              <div class="mb3 mt3 search-result-menu__header" style="font-weight: 400; font-size: 1em;">
                Продвижение
              </div>
              <SearchResultCard v-for="result in results.musicVenues" :key="result.id" :type="'music-venue'" :result="result" @edit="openEditModal" />
            </template>
            <template v-if="results.tracks.length">
              <div class="mb3 mt3 search-result-menu__header" style="font-weight: 400; font-size: 1em;">
                Треки
              </div>
              <SearchResultCard v-for="result in results.tracks" :key="result.id" :type="'track'" :result="result" @click="redirect(result.releaseId || result.id,result.requestId)" @edit="openEditModal()" />
            </template>
          </div>
          <div v-else>
            <SearchResultCard v-for="result in results" :key="result.id" :type="type" :result="result" @edit="openEditModal" @click="redirect(result.releaseId || result.id,result.requestId)" />
          </div>
          <div v-if="results.length === 0 && !loading" class="search-result-menu__no-content">
            Ничего не найдено
          </div>
        </div>
        <!-- <div v-if="loading" id="loading" /> -->
      </div>

      <!-- <div v-for="result in results" :key="result.id">
        {{ result.name }}
      </div> -->
    </div>
    <PromotionModal v-model="promotionModal" :release="selectedRelease" />
  </el-col>
</template>

<script>
import { debounce } from 'lodash';
import PromotionModal from '@/components/modals/PromotionModal.vue';
import { mapGetters } from 'vuex';
import {
  ElMessage,
  ElLoading,
} from 'element-plus';
import vClickOutside from 'click-outside-vue3';
import SearchResultCard from '../cards/SearchResultCard.vue';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    PromotionModal,
    SearchResultCard,
  },
  data: () => ({
    showMenu: false,
    selectedRelease: null,
    wasSearched: false,
    searchText: '',
    showResult: false,
    results: [],
    type: 'all',
    loading: false,
    promotionModal: false,
  }),
  mounted() {
    this.type = this.types[0].value;
  },
  watch: {
    showMenu() {
      if (this.showMenu) {
        this.getSerachResults();
      }
    },
  },
  computed: {
    types() {
      return [
        { text: 'Все', value: 'all', disabled: false },
        { text: 'Релиз', value: 'release', disabled: false },
        { text: 'Треки', value: 'track', disabled: false },
        { text: 'Продвижение', value: 'music-venue', disabled: false },
      ];
    },
    ...mapGetters(['isModerator']),
    debounceSearch() {
      return debounce(
        this.getSerachResults,
        500,
      );
    },
  },
  methods: {
    openEditModal(result) {
      this.selectedRelease = result;
      this.promotionModal = true;
      this.showMenu = false;
    },
    onInputClick() {
      if (this.searchText) {
        this.showMenu = true;
      }
    },
    onClickOutside() {
      this.showMenu = false;
    },
    redirect(id, requestId) {
      if (this.type === 'music-venue') { return; }
      if (this.isModerator) {
        this.$router.push({
          name: 'EditDraftRelease',
          params: {
            id,
          },
          query: {
            requestId,
          },

        });
      } else {
        this.$router.push(`/edit_draft_release/${id}`);
      }
      this.$router.go(1);
      this.showMenu = false;
    },

    async getSerachResults() {
      const link = `${this.isModerator ? 'search-moder' : 'search'}/${this.type}`;
      this.showMenu = true;
      const loading = ElLoading.service({
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
        target: '#loading',
      });
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/${link}?key=${this.searchText}&take=5`);
        this.results = data;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
        loading.close();
      }
    },
  },

};

</script>

<style lang="scss">
.result{
  align-items: center;
  display: flex;
  &__name{
    margin-bottom: 0.2em;
    font-size: 1em;
    font-weight: 500;
  }

  &__author{

    color:$subGrey1;
    font-size: 14px;
    font-weight: 400;
  }

  &__code{
    margin-top: 0.2em;
    color:$subGrey1;
    font-size: 13px;
    font-weight: 300;
  }

  &__image{
    border-radius: 0.5em;
    width:4.5em;
    height:4.5em;
  }

  &__info{
    margin-left: 1.1111111111111112em;
  }
}
.search-result-menu{
  z-index: 10;
  top:4.7em;
  position: absolute;
  // display: flex;
  width: 45.8333333333%;
  background-color: $primaryWhite;
  border-radius: 0.6em;
  box-shadow: -2px 2px 0.5555555555555556em 1px rgba(0, 0, 0, 0.25) !important;
  transition: 0.2s;
  min-height: 213px;
  &__result{
    cursor: pointer;
    padding: 0.5555555555555556em;
    margin: 0px 5px;
    transition: 0.2s;
    border-bottom: 1px solid $whiteOpacity20;

    &--disabled{
      opacity: 0.3;
    }

    &:hover{
      background-color: rgba(255, 255, 255, 0.04);
    }
  }

  &__header{
    font-weight: 500 !important;
    color:$subGrey1;
    padding-bottom:1em;
    border-bottom: 2px solid $subGrey2;
  }

  &__no-content{
    border-bottom: 1px solid $whiteOpacity20;
    width: 100%;
    padding: 1.1111111111111112em;
  }

  &__type-selector {
    &__wrapper{
      display: flex;
      flex-direction: row;
    }

    &__tab{
      color:$primaryGrey;
      border: 1px solid $whiteOpacity20;
      padding:  0.5em 1em;
      font-size: 1em;
      margin-right: 19px;
      margin-bottom:  0.8333333333333334em;
      border-radius: 0.5555555555555556em;
      transition: 0.3s;
      cursor: pointer;

      &:hover{
        border: 1px solid $primaryBlue !important;
      }

      &--selected{
        color:white;
        border: 1px solid $primaryBlue !important;
        background:$primaryBlue ;
      }
    }

  }

  &__header-text{
    margin-top: 1.1111111111111112em;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1em;
  }

  &__wrapper{
    padding:  2em;
    display: flex;
    flex-direction: column;
  }
}
</style>
