<template>
  <svg viewBox="0 0 113 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8876 5.66016L0 17.7651V42.099H27.0549L38.6621 30.2917V5.66016H11.8876Z" fill="#3290FF" />
    <path d="M38.029 15.8804C35.2369 11.2791 29.7138 9.55511 25.6049 12.0356C21.4961 14.5162 20.35 20.2834 23.0079 24.984C23.3309 25.4839 23.448 26.0928 23.334 26.6797C23.22 27.2666 22.884 27.7845 22.3983 28.1218C21.8742 28.3964 21.2645 28.4488 20.7025 28.2675C20.1405 28.0862 19.6719 27.6861 19.399 27.1544C16.5826 22.5406 11.0594 20.8167 6.95056 23.2972C2.84173 25.7777 1.68345 31.5573 4.34139 36.2579L4.42674 36.4068L7.75526 42.0996H15.4852L10.1328 32.9588C9.80609 32.4633 9.68554 31.8564 9.79749 31.2707C9.90944 30.685 10.2448 30.1683 10.7302 29.8334C10.9887 29.6953 11.2714 29.6105 11.5622 29.5838C11.853 29.557 12.1461 29.5889 12.4247 29.6777C12.7033 29.7664 12.962 29.9102 13.1859 30.1008C13.4098 30.2914 13.5946 30.525 13.7295 30.7884C16.5216 35.4022 22.0569 37.1385 26.1658 34.6456C30.2746 32.1527 31.4329 26.4227 28.7871 21.6973C28.6013 21.4572 28.4679 21.1795 28.3961 20.8828C28.3242 20.5861 28.3155 20.2772 28.3705 19.9768C28.4255 19.6764 28.5429 19.3914 28.715 19.1409C28.887 18.8904 29.1096 18.6801 29.368 18.5242C29.6263 18.3683 29.9145 18.2704 30.213 18.2369C30.5116 18.2034 30.8138 18.2352 31.0993 18.3301C31.3849 18.425 31.6472 18.5809 31.8687 18.7873C32.0903 18.9937 32.2659 19.2458 32.3839 19.5268L38.663 30.2675V17.0091L38.0412 15.8804H38.029Z" fill="#FBFBFB" />
    <path d="M59.8525 5.60983C62.3875 5.60983 63.7983 7.20193 63.7983 10.0722V18.1896H62.63V10.2516C62.63 7.89707 61.5498 6.75345 59.5879 6.75345C56.9868 6.75345 55.7303 9.17524 55.7303 11.9558L55.7523 18.1896H54.5619V10.2516C54.5619 7.89707 53.4598 6.75345 51.5199 6.75345C48.9187 6.75345 47.6622 9.17524 47.6622 11.9558L47.6843 18.1896H46.4939V5.8565H47.6843V8.63706C48.4117 6.82072 49.6241 5.60983 51.7844 5.60983C53.9006 5.60983 55.2453 6.73103 55.62 8.79403C56.3255 6.91042 57.604 5.60983 59.8525 5.60983Z" fill="#19191A" />
    <path d="M72.8657 5.60983C76.5912 5.60983 78.9278 8.41282 78.8617 12.4043H67.9279C68.0602 15.4539 70.1323 17.2927 72.9539 17.2927C74.8717 17.2927 76.503 16.463 77.3186 14.8485H78.531C77.539 17.2478 75.3567 18.4363 72.9319 18.4363C69.3608 18.4363 66.7376 15.88 66.7376 12.0679C66.7376 8.3007 69.3167 5.60983 72.8657 5.60983ZM72.8657 6.73103C70.1764 6.73103 68.2586 8.63706 67.95 11.2831H77.6272C77.3406 8.63706 75.533 6.73103 72.8657 6.73103Z" fill="#19191A" />
    <path d="M92.0504 0.923235H93.2188V18.1896H92.0504V15.2297C91.0805 17.1581 89.2509 18.4363 86.9583 18.4363C83.5636 18.4363 81.2049 15.723 81.2049 12.0231C81.2049 8.32313 83.5856 5.60983 86.9363 5.60983C89.2509 5.60983 91.0805 6.888 92.0504 8.81645V0.923235ZM87.1126 17.2927C89.9122 17.2927 92.0504 15.0951 92.0504 12.0231C92.0504 8.97342 89.9122 6.75345 87.1126 6.75345C84.313 6.75345 82.3732 8.97342 82.3732 12.0231C82.3732 15.0727 84.313 17.2927 87.1126 17.2927Z" fill="#19191A" />
    <path d="M97.3761 0.564453C97.9272 0.564453 98.324 0.968084 98.324 1.48383C98.324 2.02201 97.9272 2.42564 97.3761 2.42564C96.8691 2.42564 96.4502 2.02201 96.4502 1.48383C96.4502 0.968084 96.8691 0.564453 97.3761 0.564453ZM96.8029 18.1896V5.8565H97.9713V18.1896H96.8029Z" fill="#19191A" />
    <path d="M111.832 5.8565H113.001V18.1896H111.832V15.2521C110.884 17.1581 109.055 18.4363 106.762 18.4363C103.368 18.4363 101.009 15.723 101.009 12.0231C101.009 8.32313 103.39 5.60983 106.74 5.60983C109.055 5.60983 110.884 6.888 111.832 8.81645V5.8565ZM106.917 17.2927C109.716 17.2927 111.854 15.0951 111.854 12.0231C111.854 8.97342 109.716 6.75345 106.917 6.75345C104.117 6.75345 102.177 8.97342 102.177 12.0231C102.177 15.0727 104.117 17.2927 106.917 17.2927Z" fill="#19191A" />
    <path d="M56.8104 23.6117H57.9787V40.8781H56.8104V37.9182C55.8405 39.8466 54.0108 41.1248 51.7183 41.1248C48.3235 41.1248 45.9648 38.4115 45.9648 34.7116C45.9648 31.0116 48.3456 28.2983 51.6962 28.2983C54.0108 28.2983 55.8405 29.5765 56.8104 31.5049V23.6117ZM51.8726 39.9812C54.6722 39.9812 56.8104 37.7836 56.8104 34.7116C56.8104 31.6619 54.6722 29.4419 51.8726 29.4419C49.073 29.4419 47.1332 31.6619 47.1332 34.7116C47.1332 37.7612 49.073 39.9812 51.8726 39.9812Z" fill="#19191A" />
    <path d="M62.1361 23.2529C62.6872 23.2529 63.084 23.6566 63.084 24.1723C63.084 24.7105 62.6872 25.1141 62.1361 25.1141C61.6291 25.1141 61.2102 24.7105 61.2102 24.1723C61.2102 23.6566 61.6291 23.2529 62.1361 23.2529ZM61.5629 40.8781V28.545H62.7313V40.8781H61.5629Z" fill="#19191A" />
    <path d="M71.9852 41.1248C68.5023 41.1248 65.7688 38.4115 65.7688 34.7116C65.7688 31.0116 68.5023 28.2983 72.0072 28.2983C74.7407 28.2983 77.0553 29.9801 77.8048 32.6486H76.5703C75.931 30.7201 74.1675 29.4195 72.0072 29.4195C69.1415 29.4195 66.9372 31.6619 66.9372 34.7116C66.9372 37.7612 69.1415 40.0036 71.9852 40.0036C74.1675 40.0036 75.931 38.6806 76.5703 36.7746H77.8048C77.0553 39.443 74.7186 41.1248 71.9852 41.1248Z" fill="#19191A" />
    <path d="M86.1911 28.2983C89.9165 28.2983 92.2531 31.1013 92.187 35.0928H81.2533C81.3855 38.1424 83.4576 39.9812 86.2793 39.9812C88.1971 39.9812 89.8283 39.1515 90.6439 37.537H91.8563C90.8644 39.9363 88.682 41.1248 86.2572 41.1248C82.6861 41.1248 80.0629 38.5685 80.0629 34.7564C80.0629 30.9892 82.642 28.2983 86.1911 28.2983ZM86.1911 29.4195C83.5017 29.4195 81.5839 31.3256 81.2753 33.9716H90.9525C90.666 31.3256 88.8584 29.4195 86.1911 29.4195Z" fill="#19191A" />
  </svg>
</template>
