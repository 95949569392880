<template>
  <div class="header">
    <el-row>
      <el-col :span="6" class="header__name">
        <el-icon size="7em">
          <Logo />
        </el-icon>
      </el-col>

      <SearchField />

      <el-col :span="7" class="header__menu">
        <!-- <el-icon :size="30" color="green">
          <Notification />
        </el-icon> -->
        <div>
          <el-dropdown>
            <div class="ml2" style="display:flex; flex-direction:row; align-items:center">
              <el-icon size="1.5em" class="mr2">
                <User />
              </el-icon>
              <div v-if="user" class="ar-h3">
                {{ user.pseudonym }}
              </div>
              <el-icon size="1em" class="ml2">
                <ArrowDown />
              </el-icon>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <router-link class="router-link" to="/contacts">
                    Контакты
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item v-if="isModerator">
                  <router-link class="router-link" to="/registration">
                    Регистрация юзера
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item @click="$router.push('/auth'); signOut()">
                  Выход
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import {
  User,
  Logo,
} from '@/assets/icons/index.js';

import SearchField from './SearchField.vue';

export default {

  data: () => ({

  }),
  components: {
    User,
    Logo,
    SearchField,
  },
  mounted() {
    this.getProfile();
  },
  computed: {
    ...mapGetters(['isModerator']),
    ...mapState(['user']),
  },
  methods: {
    ...mapActions(['getProfile', 'signOut']),
  },
};
</script>

<style lang="scss" >

  .header{
      height: 5em;
      display:flex;
      flex-direction: column;
      justify-items: center;

      &__search{
        height: 5em;
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &--input {
          .el-input__wrapper{
            padding-left: 1.6666666666666667em !important;
            // --el-input-border-color:rgba(142, 142, 142, 0);

            input{
              --el-input-inner-height: 2.4em;
            }
          }
        }

      }

      &__menu{
        height: 5em;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      }

      &__name{
        height: 5em;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
      }
  }
</style>
