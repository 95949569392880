import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/release-list',
  },
  {
    path: '/promo-company/:id',
    name: 'PromoCompany',
    component: () => import('../views/PromoCompany.vue'),
    props: true,
  },
  {
    path: '/promotion-list',
    name: 'PromotionList',
    component: () => import('../views/PromotionList.vue'),
  },
  {
    path: '/promo',
    name: 'PromoModer',
    component: () => import('../views/PromoModer.vue'),
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import('../views/Finance.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/rate',
    name: 'Rate',
    component: () => import('../views/Rate.vue'),
  },
  {
    path: '/finance-requests',
    name: 'FinanceRequestList',
    component: () => import('../views/FinanceRequestList.vue'),
  },
  {
    path: '/promotion-link/:id',
    name: 'PromotionLink',
    component: () => import('../views/PromotionLink.vue'),
    props: true,
  },
  {
    path: '/analytic',
    name: 'Analytics',
    component: () => import('../views/Analytics.vue'),
    props: true,
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts.vue'),
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: () => import('../views/Purchase.vue'),
  },
  {
    path: '/moderation-list',
    name: 'ModerationList',
    component: () => import('../views/ModerationList.vue'),
  },
  {
    path: '/release-list',
    name: 'ReleaseList',
    component: () => import('../views/ReleaseList.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
  },
  {
    path: '/release/:id',
    name: 'Realese',
    component: () => import('../views/Realese.vue'),
    props: true,
  },
  {
    path: '/create_release',
    name: 'CreateRealese',
    component: () => import('../views/CreateRealese.vue'),
  },
  {
    path: '/edit_draft_release/:id',
    name: 'EditDraftRelease',
    component: () => import('../views/CreateRealese.vue'),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
