<template>
  <div style="width:100%;height:100%; padding:1.3888888888888888em" class="sidebar">
    <!-- <div style="color:white">
    Основная панель
    </div> -->
    <el-space direction="vertical" alignment="start">
      <router-link class="router-link" to="/main">
        <div link class="link-btn">
          <el-icon color="green">
            <Home />
          </el-icon>
          Главная
        </div>
      </router-link>
      <router-link v-if="!isModerator" class="router-link" to="/create_release">
        <div link class="link-btn">
          <el-icon color="green">
            <Release />
          </el-icon>
          Создать релиз
        </div>
      </router-link>
      <router-link v-if="isModerator" class="router-link" to="/moderation-list">
        <div link class="link-btn">
          <el-icon color="green">
            <Catalog />
          </el-icon>
          Модерация
        </div>
      </router-link>
      <router-link v-if="!isModerator" class="router-link" to="/release-list">
        <div link class="link-btn">
          <el-icon color="green">
            <Catalog />
          </el-icon>
          Каталог
        </div>
      </router-link>
      <router-link v-if="!isModerator" class="router-link" to="/analytic">
        <div v-if="!isModerator" link class="link-btn">
          <el-icon color="green">
            <Analityc />
          </el-icon>
          Аналитика
        </div>
      </router-link>
      <router-link class="router-link" :to="isModerator ? '/finance-requests' : '/finance'">
        <div link class="link-btn">
          <el-icon color="green">
            <Finance />
          </el-icon>
          Финансы
        </div>
      </router-link>
      <router-link v-if="isModerator" class="router-link" to="/users">
        <div link class="link-btn">
          <el-icon color="green">
            <User />
          </el-icon>
          Пользователи
        </div>
      </router-link>
      <router-link v-if="isModerator" class="router-link" to="/rate">
        <div link class="link-btn">
          <el-icon color="green">
            <Finance />
          </el-icon>
          Тарифы
        </div>
      </router-link>
      <router-link v-if="!isModerator" class="router-link" to="/promotion-list">
        <div link class="link-btn ">
          <el-icon color="green">
            <Promotion />
          </el-icon>
          Продвижение
        </div>
      </router-link>
      <router-link v-if="isModerator" class="router-link" to="/promo">
        <div link class="link-btn ">
          <el-icon color="green">
            <Promotion />
          </el-icon>
          Промо
        </div>
      </router-link>
    </el-space>
    <el-divider />
    <el-space direction="vertical" alignment="start">
      <div v-if="!isModerator" link class="link-btn disabled">
        <el-icon color="green">
          <User />
        </el-icon>
        Клуб
      </div>

      <!-- <router-link class="router-link" to="/purchase"> -->
      <div v-if="!isModerator" link class="link-btn disabled">
        <el-icon color="green">
          <ShoppingBag />
        </el-icon>
        Магазин
      </div>
      <!-- </router-link> -->
    </el-space>
  </div>
</template>
<script>
import {
  Home, Catalog,
  Analityc,
  Finance,
  Promotion,
  Release,
  User,
} from '@/assets/icons/index.js';
import { mapGetters } from 'vuex';

export default {
  name: 'Sidebar',
  components: {
    User,
    Release,
    Home,
    Catalog,
    Analityc,
    Finance,
    Promotion,
  },
  computed: {
    ...mapGetters(['isModerator']),
  },
};
</script>
<style lang="scss" scoped>
.link-btn{
    transition: .3s;
    font-family: 'Stolzl', sans-serif;
    height:3.05em;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width:16.6em;
    padding: 0.5rem 1.071rem 0.5rem 1.11rem;
    margin: 0;
    font-weight:400;
    border-radius: 0.5555555555555556em;
    font-size: 1.29em;
    &:hover:not(.disabled){
      background-color: $subGrey3;
    }
    &:active:not(.disabled){
      background-color: rgba(255,255,255,.4);
    }
    .el-icon{
      color: $primaryBlue;
      margin-right: 0.5em;
      font-size:2em !important;

      svg {
        font-size:2em !important;
      }
    }

    &.disabled{
      opacity: .3;
    }
  }

.router-link-active {
  .link-btn{
    color:$primaryBlue;
    border-radius: 0.5555555555555556em;
    background-color: $subGrey3;

  }
}

</style>
