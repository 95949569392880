<template>
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_928_1406)">
      <circle cx="27.5" cy="27.5" r="27.5" fill="#D9D9D9" />
      <path d="M37.2463 26.4956C37.2309 23.6278 38.5098 21.4634 41.0986 19.8693C39.6501 17.7674 37.462 16.6109 34.5728 16.3843C31.8377 16.1655 28.8483 18.0018 27.7543 18.0018C26.5986 18.0018 23.9482 16.4625 21.868 16.4625C17.5688 16.5328 13 19.9397 13 26.8706C13 28.9179 13.3698 31.0328 14.1095 33.2155C15.0956 36.0832 18.6552 43.1158 22.3688 42.9986C24.3103 42.9517 25.6817 41.5999 28.2088 41.5999C30.6589 41.5999 31.9301 42.9986 34.0951 42.9986C37.8395 42.9439 41.06 36.5521 42 33.6765C36.9766 31.2777 37.2463 26.644 37.2463 26.4956ZM32.8855 13.6651C34.9888 11.1334 34.7962 8.82828 34.7346 8C32.8778 8.10939 30.7282 9.28148 29.5032 10.7271C28.1549 12.2742 27.3613 14.1886 27.5308 16.3453C29.5417 16.5015 31.3754 15.4545 32.8855 13.6651Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_928_1406">
        <rect width="55" height="55" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
