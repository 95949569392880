<template>
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86608 3.86619C0.115555 7.61673 0.0033539 13.5847 0 25.2997C10.4984 25.2996 17.0531 20.7925 22.8026 16.8389C27.2943 13.7504 31.2945 10.9997 36.2999 10.9997C43.7499 10.9997 50.2548 16.2672 53.704 19.0604C54.1904 19.4542 54.616 19.7989 54.9751 20.0726C54.8662 11.7202 54.2803 7.01278 51.1337 3.86619C47.2675 1.83582e-06 41.045 1.28031e-06 28.5999 1.9233e-07L26.3999 0C13.9548 3.10818e-06 7.73226 2.6226e-06 3.86608 3.86619ZM24.7829 54.9997C25.3099 55 25.8488 55 26.3999 55H28.5999C29.1509 55 29.6898 55 30.2168 54.9997H24.7829Z" fill="#0077FF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000105053 25.299C10.4985 25.299 17.0532 20.7918 22.8027 16.8383C27.2943 13.7497 31.2947 10.999 36.3 10.999C43.75 10.999 50.2549 16.2666 53.7041 19.0597C54.1905 19.4536 54.6161 19.7983 54.9752 20.072C54.9999 21.9821 54.9999 24.083 54.9999 26.3994V28.5994C54.9999 41.0444 54.9999 47.267 51.1338 51.1332C47.4387 54.8282 41.5914 54.9918 30.2165 54.999H24.7834C13.4086 54.9918 7.56122 54.8282 3.86619 51.1332C-1.57356e-06 47.267 -1.09883e-06 41.0444 1.17303e-09 28.5994L5.58476e-07 26.3994C5.58476e-07 26.0271 5.52869e-07 25.6603 0.000105053 25.299Z" fill="#F45FFF" />
    <path d="M42.6274 28.0355C42.6274 26.6761 43.7431 25.5742 45.1024 25.5742C46.4618 25.5742 47.5774 26.6761 47.5774 28.0355V37.963C47.5774 39.3222 46.4618 40.4242 45.1024 40.4242C43.7431 40.4242 42.6274 39.3222 42.6274 37.963V28.0355Z" fill="white" />
    <path d="M33.8228 21.7113C33.8228 20.3519 34.9384 19.25 36.2978 19.25C37.6571 19.25 38.7728 20.3519 38.7728 21.7113V44.2887C38.7728 45.6481 37.6571 46.75 36.2978 46.75C34.9384 46.75 33.8228 45.6481 33.8228 44.2887V21.7113Z" fill="white" />
    <path d="M25.0249 28.0355C25.0249 26.6761 26.1406 25.5742 27.4999 25.5742C28.8592 25.5742 29.9749 26.6761 29.9749 28.0355V37.963C29.9749 39.3222 28.8592 40.4242 27.4999 40.4242C26.1406 40.4242 25.0249 39.3222 25.0249 37.963V28.0355Z" fill="white" />
    <path d="M16.2231 31.6116C16.2231 30.2523 17.3389 29.1504 18.6981 29.1504C20.0575 29.1504 21.1731 30.2523 21.1731 31.6116V34.3891C21.1731 35.7485 20.0575 36.8504 18.6981 36.8504C17.3389 36.8504 16.2231 35.7485 16.2231 34.3891V31.6116Z" fill="white" />
    <path d="M7.42188 31.6116C7.42188 30.2523 8.53761 29.1504 9.89688 29.1504C11.2562 29.1504 12.3719 30.2523 12.3719 31.6116V34.3891C12.3719 35.7485 11.2562 36.8504 9.89688 36.8504C8.53761 36.8504 7.42188 35.7485 7.42188 34.3891V31.6116Z" fill="white" />
  </svg>
</template>
