<template>
  <div :class="{'search-result-menu__result--disabled':false}" class="search-result-menu__result">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <div class="result">
        <el-image
          ref="image"
          class="result__image"
          :src="result.previewImage"
          fit="cover"
        >
          <template #error>
            <div class="image-slot">
              <el-icon size="20">
                <Catalog />
              </el-icon>
            </div>
          </template>
        </el-image>
        <div class="result__info">
          <div class="result__name">
            {{ result.name || LONG_DASH }}
          </div>
          <div class="result__author">
            {{ result.author || LONG_DASH }}
          </div>
          <div v-if="type==='release' || type==='music-venue'" class="result__code">
            {{ result.upc || LONG_DASH }}
          </div>
          <div v-if="type==='track'" class="result__code">
            {{ result.isrc || LONG_DASH }}
          </div>
        </div>
      </div>
      <div v-if="type==='music-venue' || result.type === 'music-venue'" style="display: flex; flex-direction: row; align-items: center;">
        <div class="promotion-card__btn" @click="copy(result.releaseId)">
          <el-icon size="20" color="success">
            <Copy />
          </el-icon>
        </div>
        <div class="promotion-card__btn ml3" @click="openModal(result)">
          <el-icon size="24" color="success">
            <Edit />
          </el-icon>
        </div>
      </div>
    </div>
    <!-- <template v-else>
                {{ result.name }}
              </template> -->
  </div>
</template>

<script>
import { Edit, Copy, Catalog } from '@/assets/icons/index.js';
import {
  ElMessage,
  // ElLoading,
} from 'element-plus';

export default {
  components: {
    Copy,
    Edit,
    Catalog,
  },
  props: {
    type: String,
    result: Object,
  },
  methods: {
    openModal(result) {
      this.$emit('edit', result);
    },
    copy(id) {
      navigator.clipboard.writeText(`${window.location.host}/promotion-link/${id}`);
      ElMessage({ message: 'Ссылка успешно скопирована', type: 'success' });
    },
  },
};

</script>
