<template>
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_967_324)">
      <path d="M11.3278 5.62147C15.3741 2.6232 20.1565 0.810722 25.1408 0.360352V8.60821C21.9189 9.02591 18.8418 10.2595 16.2097 12.2098C12.9139 14.652 10.4902 18.0886 9.29617 22.013C8.10207 25.9373 8.2008 30.1415 9.57777 34.0053C10.9547 37.8693 13.537 41.1887 16.9438 43.4733C20.3506 45.758 24.4014 46.8873 28.4989 46.695C32.5963 46.5023 36.5233 44.998 39.701 42.404C42.8783 39.8097 45.138 36.263 46.1467 32.2871C46.8503 29.5137 46.919 26.6338 46.368 23.8574L53.294 18.2894L53.2893 18.2445C55.159 23.3874 55.4447 28.9825 54.095 34.3033C52.654 39.9833 49.426 45.05 44.8867 48.756C40.3473 52.4617 34.7373 54.6107 28.8838 54.8857C23.0303 55.1607 17.2434 53.5473 12.3765 50.2833C7.50973 47.0197 3.8207 42.278 1.85361 36.758C-0.113488 31.2381 -0.254521 25.2321 1.45131 19.626C3.15715 14.0198 6.61953 9.11024 11.3278 5.62147Z" fill="#FCCA00" />
      <path d="M48.0718 9.47104L48.1008 9.54371L43.4725 16.8948C41.7892 14.4005 39.5338 12.3244 36.8941 10.8549V27.5836C36.8941 32.7162 32.7334 36.877 27.6009 36.877C22.4683 36.877 18.3076 32.7162 18.3076 27.5836C18.3076 22.4511 22.4683 18.2903 27.6009 18.2903C29.5244 18.2903 31.3115 18.8748 32.7942 19.8757V0.743164C38.8278 1.90368 44.1555 5.04811 48.0718 9.47104Z" fill="#FC3F1D" />
    </g>
    <defs>
      <clipPath id="clip0_967_324">
        <rect width="55" height="55" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
